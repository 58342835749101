import React, { createContext, useContext } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import axios from 'axios';
import { useAuth } from './AuthContext';

const CustomerContext = createContext();

const queryClient = new QueryClient();

export const CustomerProvider = ({ children }) => {
  const { isLoggedIn } = useAuth();

  const fetchCustomers = async () => {
    const token = localStorage.getItem('token');
    const response = await axios.get('https://pms.usmanmobiles.logicalwebsol.com/Back_end/getCustomer', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  const { data: customers, refetch } = useQuery('customers', fetchCustomers, {
    enabled: isLoggedIn,
  });

  return (
    <CustomerContext.Provider value={{ customers, refetch }}>
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomers = () => useContext(CustomerContext);


import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import "../Components/Home.css"
import ImageGalleryModal from './ImageGalleryModal';

import './ImageGalleryModal.css';
import qz from 'qz-tray';
import { useCustomers } from './CustomerContext';

const HomePage = () => {
  const configRef = useRef(null);
  const [isConnected, setIsConnected] = useState(false);
  const isConnecting = useRef(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editedCustomer, setEditedCustomer] = useState({
    id: '',
    name: '',
    cnic: '',
    address: '',
    mob_no: '',
    mob_brand: '',
    delivery_date: '',
    deliver_to: '',
    submitted_date: '',
    deliver_pic: []
  });

  const { customers, refetch } = useCustomers();

  const [capturedImages, setCapturedImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [captureCount, setCaptureCount] = useState(0);
  const [showCamera, setShowCamera] = useState(true);
  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const intervalRef = useRef(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeImages, setActiveImages] = useState([]);

  const handleImageClick = (images) => {
    setActiveImages(images);
    setModalOpen(true);
  };

  const handleCloseModel = () => {
    setModalOpen(false);
  };
  useEffect(() => {
    // Function to connect to QZ Tray
    const connectQZ = () => {
      if (isConnecting.current) return;
      isConnecting.current = true;
      qz.websocket.connect()
        .then(() => {
          console.log("Connected to QZ Tray!");
          configRef.current = qz.configs.create("BIXOLON SRP-275II");
          setIsConnected(true);
        })
        .catch(err => {
          console.error("Failed to connect to QZ Tray:", err);
          setIsConnected(false);
        })
        .finally(() => {
          isConnecting.current = false;
        });
    };

    connectQZ();

    // Clean up the connection when the component unmounts
    return () => {
      qz.websocket.disconnect()
        .then(() => {
          console.log("Disconnected from QZ Tray.");
          setIsConnected(false);
        })
        .catch(err => {
          console.error("Error during disconnection from QZ Tray:", err);
        });
    };
  }, []);

  
  const printCustomerDetails = (customer) => {
    if (!isConnected) {
        console.error("Cannot print: QZ Tray is not connected.");
        return;
    }

    const printableContent = `
        <!DOCTYPE html>
        <html>
        <head>
            <title>Print - Customer Details</title>
            <style>
            @page {
              margin: 0;
              
          }
                body {   
                 
                    font-family: Arial, sans-serif; 
                    margin: 3px; 
                    font-size: 12px; 
                }
                .customer-details { 
                    width: 100%; 
                    font-size: 12px; 
                    margin-top: 10px; 
                    margin-bottom: 10px; 
                    border: none; /* Remove border */
                    border-spacing: 0; /* Remove padding */
                }
                .customer-details th, .customer-details td { 
                    text-align: left; 
                    padding: 0; /* Remove padding */
                }
            
                .customer-heading { 
                    text-align: center; 
                    color: #333; 
                    font-size: 16px; 
                    margin: 0; 
                    padding: 0; 
                }
                .customer-address { 
                    text-align: center; 
                    color: #333; 
                    padding: 0; 
                    font-size: 10px; 
                    margin: 0; 
                }
                .customer-bot { 
                  margin-top: 10px;
              }
                .disclaimer { 
                    text-align: center; 
                    margin-top: 20px; 
                    font-size: 10px; 
                    color: #666; 
                }
            </style>
        </head>
        <body>
            <h1 class="customer-heading">USMAN MOBILE</h1>
            <p class="customer-address">TOWER BAZAR FATEH PUR</p>
            <p class="customer-address">PH: 0313-7457151</p>
            <table class="customer-details">
                <tr><th>Name:</th><td>${customer.name}</td></tr>
                <tr><th>CNIC:</th><td>${customer.cnic}</td></tr>
                <tr><th>Address:</th><td>${customer.address}</td></tr>
                <tr><th>Mobile Number:</th><td>${customer.mob_no}</td></tr>
                <tr><th>Mobile Brand:</th><td>${customer.mob_brand}</td></tr>
                <tr><th>Charges:</th><td>${customer.charges}</td></tr>
                <tr><th>Submitted Date:</th><td>${customer.submitted_date}</td></tr>
            </table>
            <p class="disclaimer">Disclaimer: Kindly collect your device within one month. We cannot be held unclaimed items after this period. Thank you.</p>
            <p class="customer-address">Powered By: logicalwebsol.com </p>
            <p class="customer-address">0346-9196274 </p>
            <p class="customer-bot">. </p>
           
        </body>
        </html>
    `;

    // const printWindow = window.open('', '_blank', 'height=600,width=800');
    // printWindow.document.write(printableContent);
    // printWindow.document.close();
    // printWindow.focus();
    // printWindow.print();
    // printWindow.close();
    qz.print(configRef.current, [{
      type: 'html',
      format: 'file',
      data: 'data:text/html,' + encodeURIComponent(printableContent)
    }])
      .then(() => {
        console.log("Print job submitted successfully.");
      })
      .catch(err => {
        console.error("Failed to submit print job:", err);
      });
};


  useEffect(() => {
    if (captureCount >= 8) {
      clearInterval(intervalRef.current);
      setShowCamera(false);
      if (streamRef.current) {
        const tracks = streamRef.current.getTracks();
        tracks.forEach(track => track.stop());
      }
    }
  }, [captureCount]);

  const handleStartCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
      streamRef.current = stream;
      setCapturedImages([]);
      setSelectedImages([]);
      setShowCamera(true);
      setCaptureCount(0);
      intervalRef.current = setInterval(handleCapturePicture, 1000);
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };

  const handleCapturePicture = () => {
    const video = videoRef.current;
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    const dataURL = canvas.toDataURL('image/jpeg');
    setCapturedImages(prev => [...prev, dataURL]);
    setCaptureCount(prev => prev + 1);
  };

  const handleImageSelect = (image) => {
    if (selectedImages.includes(image)) {
      setSelectedImages(selectedImages.filter(item => item !== image));
    } else {
      setSelectedImages([...selectedImages, image]);
    }
  };

  const token = localStorage.getItem('token');

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this customer?");
    if (confirmDelete) {
      try {
        const response = await axios.delete(`https://pms.usmanmobiles.logicalwebsol.com/Back_end/DELETE/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        console.log(response.data);
        if (response.data.error) {
          alert(response.data.error);
          return false;
        }
        refetch(); // Reload customers list after deletion
      } catch (error) {
        console.error('Error deleting customer:', error);
        alert('An error occurred while trying to delete the customer. Please try again.');
      }
    } else {
      console.log("Deletion cancelled by user.");
    }
  };

  const handleEdit = (customer) => {
    setEditedCustomer(customer);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);

  };

  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const day = dateTime.getDate().toString().padStart(2, '0');
    const month = (dateTime.getMonth() + 1).toString().padStart(2, '0');
    const year = dateTime.getFullYear();
    const hours = dateTime.getHours().toString().padStart(2, '0');
    const minutes = dateTime.getMinutes().toString().padStart(2, '0');
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  function setCurrentDatePKR() {
    let currentDate = new Date();
    currentDate.setHours(currentDate.getHours());
    let year = currentDate.getFullYear();
    let month = String(currentDate.getMonth() + 1).padStart(2, '0');
    let day = String(currentDate.getDate()).padStart(2, '0');
    let hours = String(currentDate.getHours()).padStart(2, '0');
    let minutes = String(currentDate.getMinutes()).padStart(2, '0');
    let formattedDate = `${year}-${month}-${day} , ${hours}:${minutes}`;
    setEditedCustomer({ ...editedCustomer, delivery_date: formattedDate });
  }

  const handleSaveChanges = async () => {
    try {
      const formData = new FormData();
      formData.append('id', editedCustomer.id);
      formData.append('name', editedCustomer.name);
      formData.append('cnic', editedCustomer.cnic);
      formData.append('address', editedCustomer.address);
      formData.append('mob_no', editedCustomer.mob_no);
      formData.append('mob_brand', editedCustomer.mob_brand);
      formData.append('charges', editedCustomer.charges);
      if (editedCustomer.delivery_date) {
        formData.append('delivery_date', editedCustomer.delivery_date);
      }
      if (editedCustomer.deliver_to) {
        formData.append('deliver_to', editedCustomer.deliver_to);
      }
      
      if (selectedImages.length) {
        const imagePromises = selectedImages.map(async (imgDataUrl, index) => {
          const response = await fetch(imgDataUrl);
          const blob = await response.blob();
          return new File([blob], `deliver_pic_${index}_${new Date().toISOString()}.jpeg`, { type: 'image/jpeg' });
        });
        const imageFiles = await Promise.all(imagePromises);
        imageFiles.forEach(file => {
          formData.append('deliver_pic[]', file);
        });
      }

      for (let pair of formData.entries()) {
        console.log(`${pair[0]}:`, pair[1]);
      }

      const token = localStorage.getItem('token');
      const response = await axios.post(`https://pms.usmanmobiles.logicalwebsol.com/Back_end/update/${editedCustomer.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });

      console.log('updated data', response.data);
      if (response.data.error) {
        alert(response.data.error);
        return;
      }

      refetch(); // Refetch customers after saving changes
      setShowCamera(true);
      setSelectedImages([]);
      setCapturedImages([]);
      setShowModal(false);

      alert("Updated successfully");
    } catch (error) {
      console.error('Error updating customer:', error);
    }
  };

  useEffect(() => {
    if (customers) {
      setFilteredCustomers(customers.filter(customer => !customer.delivery_date || !customer.deliver_to || !customer.deliver_pic || JSON.parse(customer.deliver_pic).length === 0));
    }
  }, [customers]);

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    const completedCustomers = customers.filter(customer => !customer.delivery_date || !customer.deliver_to || !customer.deliver_pic || JSON.parse(customer.deliver_pic).length === 0);

    if (searchTerm.trim() === '') {
      setFilteredCustomers(completedCustomers);
      return;
    }

    const lowercasedFilter = searchTerm.toLowerCase();
    const filteredData = completedCustomers.filter(item => {
      return Object.keys(item).some(key =>
        item[key] != null && item[key].toString().toLowerCase().includes(lowercasedFilter)
      );
    });
    setFilteredCustomers(filteredData);
    console.log('Filtered Data:', filteredData);
  };

  if (!customers) {
    return <div>Loading...</div>;
  }

 

  return (
    <div className=' mt-5 customer'>
     
      
      <div className="input-group mb-3 search">
       <input   className="form-control update" type="text"   placeholder="Search by name, CNIC, address, etc." value={searchTerm} onChange={handleSearchChange} />
    
      </div>
      <table className="table table-striped">
        <thead>
          <tr className='table-row'>
          <th>S.No</th>
            <th>Name</th>
            <th>CNIC</th>
            <th>Address</th>
            <th>Mobile Number</th>
            <th>Mobile Brand</th>
            <th>Charges</th>
            <th>Submitted Date</th>
            <th>User Picture</th>
            <th>Delivery Date</th>
            <th>Deliver To</th>
            <th>Delivery Picture</th>
            <th>Deliver</th>
            <th>Print</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
        {Array.isArray(filteredCustomers) && filteredCustomers.map((customer, index) => (
            <tr className='table-row' key={customer.id}>
                <td>{index + 1}</td>
                <td>{customer.name}</td>
                <td>{customer.cnic}</td>
                <td>{customer.address}</td>
                <td>{customer.mob_no}</td>
                <td>{customer.mob_brand}</td>
                <td>{customer.charges}</td>
                <td>{formatDateTime(customer.submitted_date)}</td>
                <td>
              
              {customer.user_pic && JSON.parse(customer.user_pic).length > 0 ? (
                <img
                  src={`https://pms.usmanmobiles.logicalwebsol.com/Back_end/${JSON.parse(customer.user_pic)[0]}`}
                  alt="User"
                  style={{ width: '58px', cursor: 'pointer' }}
                  onClick={() => handleImageClick(JSON.parse(customer.user_pic).map(pic => `https://pms.usmanmobiles.logicalwebsol.com/Back_end/${pic}`))}
                />
              ) : '--'}
            </td>
                <td>{customer.delivery_date ? formatDateTime(customer.delivery_date) : '--'}</td>
                <td>{customer.deliver_to || '--'}</td>
               <td>
  {customer.deliver_pic && JSON.parse(customer.deliver_pic).length > 0 ? (
    <img
      src={`https://pms.usmanmobiles.logicalwebsol.com/Back_end/${JSON.parse(customer.deliver_pic)[0]}`} // Display only the first image
      alt="Delivery"
      style={{ width: '58px', cursor: 'pointer' }}
      onClick={() => handleImageClick(JSON.parse(customer.deliver_pic).map(pic => `https://pms.usmanmobiles.logicalwebsol.com/Back_end/${pic}`))} // Opens modal with all images
    />
  ) : '--'}
</td>

                <td> 
                  <button className="btn btn-primary ms-2" onClick={() => handleEdit(customer)}>Deliver</button>
                </td>
                <td><button className="btn btn-primary ms-2" onClick={() => printCustomerDetails(customer)}>Print</button></td>
                <td>
                    <button className="btn btn-danger" onClick={() => handleDelete(customer.id)}>Delete</button>
                   
                </td>
            </tr>
        ))}
        </tbody>
        <ImageGalleryModal isOpen={isModalOpen} images={activeImages} onClose={handleCloseModel} />
      </table>
      {/* Modal for editing customer information */}
      {showModal && (
  <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block', height:'90%' }}>
    <div className="modal-dialog edit" role="document">
      <div className="modal-content">
        <div className="modal-header head">
          <h5 className="modal-title m-title">Edit Customer Information</h5>
          <button type="button" className="btn-close" onClick={handleCloseModal}></button>
        </div>
        <div className="modal-body">
          <form>
            <div className="row mb-3">
              <div className="col">
                <label htmlFor="name" className="form-label uplable">Name</label>
                <input type="text" className="form-control update " id="name" name="name" value={editedCustomer.name} onChange={(e) => setEditedCustomer({ ...editedCustomer, name: e.target.value })} />
              </div>
              <div className="col">
                <label htmlFor="cnic" className="form-label uplable">CNIC</label>
                <input type="text" className="form-control update" id="cnic" name="cnic" value={editedCustomer.cnic} onChange={(e) => setEditedCustomer({ ...editedCustomer, cnic: e.target.value })} />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label htmlFor="address" className="form-label uplable">Address</label>
                <input type="text" className="form-control update" id="address" name="address" value={editedCustomer.address} onChange={(e) => setEditedCustomer({ ...editedCustomer, address: e.target.value })} />
              </div>
              <div className="col">
                <label htmlFor="mob_no" className="form-label uplable">Mobile Number</label>
                <input type="text" className="form-control update" id="mob_no" name="mob_no" value={editedCustomer.mob_no} onChange={(e) => setEditedCustomer({ ...editedCustomer, mob_no: e.target.value })} />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label htmlFor="mob_brand" className="form-label uplable">Mobile Brand</label>
                <input type="text" className="form-control update" id="mob_brand" name="mob_brand" value={editedCustomer.mob_brand} onChange={(e) => setEditedCustomer({ ...editedCustomer, mob_brand: e.target.value })} />
              </div>
              <div className="col">
                <label htmlFor="charges" className="form-label uplable">Charges</label>
                <input type="text" className="form-control update" id="charges" name="charges" value={editedCustomer.charges} onChange={(e) => setEditedCustomer({ ...editedCustomer, charges: e.target.value })} />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label htmlFor="delivery_date" className="form-label uplable">Delivery Date</label>
                <div className="input-group">
                  <input 
                    // type="datetime-local" 
                    className="form-control update" 
                    id="delivery_date" 
                    name="delivery_date" 
                    value={editedCustomer.delivery_date} 
                    // onChange={(e) => setEditedCustomer({ ...editedCustomer, delivery_date: e.target.value })} 
                  />
                  <button className="btn btn-outline-secondary deltime" type="button" onClick={setCurrentDatePKR}> Current Date&Time</button>
                </div>
              </div>
              <div className="col">
                <label htmlFor="deliver_to" className="form-label uplable">Deliver To</label>
                <input type="text" className="form-control update" id="deliver_to" name="deliver_to" value={editedCustomer.deliver_to} onChange={(e) => setEditedCustomer({ ...editedCustomer, deliver_to: e.target.value })} />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <div className="web-container">
                  {showCamera && (
                    <>
                      <div className="start-button"><button type="button" className="btn btn-secondary" onClick={handleStartCamera}>Start Camera</button></div>
                      <div>
                        <video ref={videoRef} autoPlay style={{ width: '30%' }}></video>
                      </div>
                    </>
                  )}
                  <div className="image-row showImage">
                    {capturedImages.map((image, index) => (
                      <img key={index} src={image} alt={`Captured ${index}`} onClick={() => handleImageSelect(image)} style={{ width: '100px', height: '100px', border: selectedImages.includes(image) ? '2px solid green' : '1px solid black', cursor: 'pointer', margin: '5px' }} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="modal-footer edit">
          {/* <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Close</button> */}
          <button type="button" className="btn btn-primary " onClick={handleSaveChanges}>Save changes</button>
        </div>
      </div>
    </div>
  </div>
)}

    </div>
  );
};

export default HomePage;

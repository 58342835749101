import React from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { AuthProvider } from './AuthContext';
import { CustomerProvider } from './CustomerContext';

const queryClient = new QueryClient();

export const AppProviders = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <CustomerProvider>
        {children}
      </CustomerProvider>
    </AuthProvider>
  </QueryClientProvider>
);

import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // Set the initial token value in local storage to "empty" if it doesn't exist
  if (!localStorage.getItem('token')) {
    localStorage.setItem('token', 'empty');
  }

  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('token') !== 'empty');

  useEffect(() => {
    setIsLoggedIn(localStorage.getItem('token') !== 'empty');
  }, []);

  const logIn = (token) => {
    if (token && token !== 'undefined' && token !== 'empty') {
      localStorage.setItem('token', token);
      setIsLoggedIn(true);
    } else {
      console.error('Invalid token, not storing to local storage');
    }
  };

  const logOut = () => {
    localStorage.setItem('token', 'empty');
    setIsLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, logIn, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

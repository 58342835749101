// import React from "react";
// import "./App.css";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { ThemeProvider, createTheme } from "@mui/material/styles";
// import Navbar from "./Components/Navbar";
// import Home from "./Components/Home";
// import MatrimonialTable from "./Components/MatrimonialTable ";
// import CompletedJobs from "./Components/CompletedJobs";
// import PendingJobs from "./Components/PendingJobs";
// import { AuthProvider } from "./Components/AuthContext";
// import Footer from "./Components/Footer"
// import ForgotPassword from './Components/ForgotPassword';
// import ResetPassword from './Components/ResetPassword';

// const theme = createTheme();

// function App() {
//   return (
//     <ThemeProvider theme={theme}>
//       <AuthProvider>
//         <Router>
//           <div className="App">
//             <Navbar />
//           <div className="content">
//           <Routes>
//               <Route path="/" element={<Home />} />
//               <Route path="/customer" element={<MatrimonialTable />} />
//               <Route path="/completed-jobs" element={<CompletedJobs />} />
//               <Route path="/pending-jobs" element={<PendingJobs />} /> 
//               <Route path="/forgot-password" element={<ForgotPassword />} />
//               <Route path="/reset-password" element={<ResetPassword/>} />
             
//               </Routes>
//           </div>
//             <Footer />
//           </div>
//         </Router>
//       </AuthProvider>
//     </ThemeProvider>
//   );
// }

// export default App;

import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Navbar from "./Components/Navbar";
import Home from "./Components/Home";
import MatrimonialTable from "./Components/MatrimonialTable ";
import CompletedJobs from "./Components/CompletedJobs";
import PendingJobs from "./Components/PendingJobs";
import Footer from "./Components/Footer";
import ForgotPassword from './Components/ForgotPassword';
import ResetPassword from './Components/ResetPassword';
import { AppProviders } from "./Components/AppProviders"; // Import AppProviders

const theme = createTheme();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppProviders> {/* Wrap with AppProviders */}
        <Router>
          <div className="App">
            <Navbar />
            <div className="content">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/customer" element={<MatrimonialTable />} />
                <Route path="/completed-jobs" element={<CompletedJobs />} />
                <Route path="/pending-jobs" element={<PendingJobs />} /> 
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
              </Routes>
            </div>
            <Footer />
          </div>
        </Router>
      </AppProviders>
    </ThemeProvider>
  );
}

export default App;


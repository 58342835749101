import React, { useState } from 'react';
import './ForgotPassword.css'; // Import CSS file

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('Form body email:', email); // Debug: Log email

        try {
            const response = await fetch("https://pms.usmanmobiles.logicalwebsol.com/Back_end/forgot-password", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email }) // Ensure email is in the request body
            });

            const result = await response.json();
            if (result.message) {
                setMessage('Password reset email sent');
            } else {
                setMessage(result.error);
            }
        } catch (error) {
            console.log('error', error);
            setMessage('An error occurred. Please try again later.');
        }
    };

    return (
        <div className="forgot-password-container" >
            <h2>Forgot Password</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary">Send Reset Link</button>
            </form>
            {message && <p className="message">{message}</p>}
        </div>
    );
};

export default ForgotPassword;

import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
// import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout"; // Import logout icon for display
import "../Components/Navbar.css";
import { HiMenu } from "react-icons/hi";
import { useAuth } from './AuthContext';

const Navbar = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [openMenu, setOpenMenu] = useState(false);
  const [openLoginForm, setOpenLoginForm] = useState(false);
  const navigate = useNavigate(); // Hook for programmatic navigation
  const { isLoggedIn, logOut } = useAuth();
  const [menuOptions, setMenuOptions] = useState([]);

  // Define handleLogout function using useCallback
  const handleLogout = useCallback(() => {
    logOut();
    setOpenLoginForm(false);
    navigate('/');
  }, [logOut, setOpenLoginForm, navigate]);

  // This effect listens for changes to isLoggedIn to update menu options
  useEffect(() => {
    const options = isLoggedIn ? [
      {
        text: "Add Customer",
        link: "/customer",
      },
      {
        text: "Pending Jobs",
        link: "/pending-jobs",
      },
      {
        text: "Completed Jobs",
        link: "/completed-jobs",
      },
      {
        text: "Logout",
        icon: <LogoutIcon />,
        action: handleLogout,
      }
    ] : [
    
      {
        text: "Login",
        icon: <HomeIcon />,
        link: "/",
      },
    ];

    setMenuOptions(options);
  }, [isLoggedIn, handleLogout]);

  const renderMenuItems = (isMobile) => menuOptions.map((item) => {
    const listItemClass = isMobile ? "mobile-nav-item" : "";

    if (item.action) {
      return (
        <ListItem className="nav-item" key={item.text} disablePadding >
          <ListItemButton className="item-white" onClick={item.action}>
            <ListItemText primary={item.text} className="nav-item-white" />
          </ListItemButton>
        </ListItem>
      );
    } else {
      return (
        <ListItem>
            <ListItemButton>
            <Link key={item.text} to={item.link} className={`nav-item-white ${listItemClass}`}>
              <ListItemText primary={item.text} className="nav-item-white" />
        </Link>
            </ListItemButton>
          </ListItem>
      );
    }
  });

  const renderDesktopMenu = () => (
    <div className="navbar-links-container">
      {renderMenuItems(false)}
    </div>
  );

  const renderMobileMenu = () => (
    <div className="navbar-menu-container">
      <HiMenu onClick={() => setOpenMenu(true)} />
      <Drawer
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        anchor="right"
        classes={{ paper: 'mobile-drawer' }}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
          className="mobile-drawer"
        >
          <IconButton
            onClick={() => setOpenMenu(false)}
            sx={{ color: 'white', padding: '10px', float: 'right' }}
          >
            <CloseIcon />
          </IconButton>
          <List>
            {renderMenuItems(true)}
          </List>
          <Divider />
        </Box>
      </Drawer>
    </div>
  );

  return (
    <nav>
      <div className="nav-logo-container">
        {/* <img src={Logo} alt="Logo" /> */}
      </div>
      <div className="header">
        {/* <h2 className='bkm1'>BAZM-E-KHIZAR MILLAT</h2>
        <h1 className='com2'>Community Group</h1> */}
      </div>
      {!isMobile ? renderDesktopMenu() : renderMobileMenu()}
      {/* <LoginForm open={openLoginForm} handleClose={() => setOpenLoginForm(false)} /> */}
    </nav>
  );
};

export default Navbar;

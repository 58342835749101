import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './ResetPassword.css'; // Import CSS file

const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const query = new URLSearchParams(useLocation().search);
    const token = query.get('token');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (newPassword !== confirmPassword) {
            setMessage('Passwords do not match');
            return;
        }

        try {
            const response = await fetch("https://pms.usmanmobiles.logicalwebsol.com/Back_end/reset-password", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ token, password: newPassword })
            });

            if (!response.ok) {
                // If the response status is not OK, log the status and status text
                console.error('HTTP error', response.status, response.statusText);
                setMessage('An error occurred. Please try again later.');
                return;
            }
            

            const result = await response.json();
            if (result.message) {
                setMessage(result.message);
            } else {
                setMessage(result.error || 'An error occurred. Please try again later.');
            }
            if(result.message==='Password updated successfully'){
                navigate('/');
            }
        } catch (error) {
            console.log('Fetch error', error);
            setMessage('An error occurred. Please try again later.');
        }

    };

    return (
        <div className="reset-password-container" >
            <h2 style={{ textAlign: 'center' }}>Reset Password</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="newPassword" className="form-label">New Password</label>
                    <input
                        type="password"
                        className="form-control"
                        id="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                    <input
                        type="password"
                        className="form-control"
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary">Reset Password</button>
            </form>
            {message && <p className="message">{message}</p>}
        </div>
    );
};

export default ResetPassword;

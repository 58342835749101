import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "../Components/Home.css";
import ImageGalleryModal from './ImageGalleryModal';
import './ImageGalleryModal.css';
import { useCustomers } from './CustomerContext';

const CompletedJobs = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const { customers: allCustomers, refetch: refreshCustomers } = useCustomers();
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeImages, setActiveImages] = useState([]);

  const [filteredCustomers, setFilteredCustomers] = useState([]);

  useEffect(() => {
    if (allCustomers) {
      setFilteredCustomers(allCustomers.filter(customer => customer.delivery_date && customer.deliver_to && customer.deliver_pic && JSON.parse(customer.deliver_pic).length > 0));
    }
  }, [allCustomers]);
  

  const handleImageClick = (images) => {
    setActiveImages(images);
    setModalOpen(true);
  };

  const handleCloseModel = () => {
    setModalOpen(false);
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this customer?");
    if (confirmDelete) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.delete(`https://pms.usmanmobiles.logicalwebsol.com/Back_end/DELETE/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        console.log(response.data);
        if (response.data.error) {
          alert(response.data.error);
          return false;
        }
        refreshCustomers(); // Refresh customers list after deletion
      } catch (error) {
        console.error('Error deleting customer:', error);
        alert('An error occurred while trying to delete the customer. Please try again.');
      }
    } else {
      console.log("Deletion cancelled by user.");
    }
  };

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);

    const completedCustomers = allCustomers.filter(customer => customer.delivery_date && customer.deliver_to && customer.deliver_pic && JSON.parse(customer.deliver_pic).length > 0);
  
    if (searchTerm.trim() === '') {
      setFilteredCustomers(completedCustomers);
      return;
    }
  
    const lowercasedFilter = searchTerm.toLowerCase();
    const filteredData = completedCustomers.filter(item => {
      return Object.keys(item).some(key =>
        item[key] != null && item[key].toString().toLowerCase().includes(lowercasedFilter)
      );
    });
    setFilteredCustomers(filteredData);
  };

  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const day = dateTime.getDate().toString().padStart(2, '0');
    const month = (dateTime.getMonth() + 1).toString().padStart(2, '0');
    const year = dateTime.getFullYear();
    const hours = dateTime.getHours().toString().padStart(2, '0');
    const minutes = dateTime.getMinutes().toString().padStart(2, '0');
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  return (
    <div className='mt-5 customer'>
      <div className="input-group mb-3">
        <input className="form-control" type="text" placeholder="Search by name, CNIC, address, etc." value={searchTerm} onChange={handleSearchChange} />
     
      </div>
      <table className="table table-striped">
        <thead>
          <tr className='table-row'>
           <th>S.No</th>
            <th>Name</th>
            <th>CNIC</th>
            <th>Address</th>
            <th>Mobile Number</th>
            <th>Mobile Brand</th>
            <th>Charges</th>
            <th>Submitted Date</th>
            <th>User Picture</th>
            <th>Delivery Date</th>
            <th>Deliver To</th>
            <th>Delivery Picture</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredCustomers.map((customer, index) => (
            <tr className='table-row' key={customer.id}>
               <td>{index + 1}</td>
              <td>{customer.name}</td>
              <td>{customer.cnic}</td>
              <td>{customer.address}</td>
              <td>{customer.mob_no}</td>
              <td>{customer.mob_brand}</td>
              <td>{customer.charges}</td>
              <td>{formatDateTime(customer.submitted_date)}</td>
              <td>
                {customer.user_pic && JSON.parse(customer.user_pic).length > 0 ? (
                  <img
                    src={`https://pms.usmanmobiles.logicalwebsol.com/Back_end/${JSON.parse(customer.user_pic)[0]}`}
                    alt="User"
                    style={{ width: '58px', cursor: 'pointer' }}
                    onClick={() => handleImageClick(JSON.parse(customer.user_pic).map(pic => `https://pms.usmanmobiles.logicalwebsol.com/Back_end/${pic}`))}
                  />
                ) : 'No picture'}
              </td>
              <td>{customer.delivery_date ? formatDateTime(customer.delivery_date) : 'Not delivered'}</td>
              <td>{customer.deliver_to || 'Not specified'}</td>
              <td>
                {customer.deliver_pic && JSON.parse(customer.deliver_pic).length > 0 ? (
                  <img
                    src={`https://pms.usmanmobiles.logicalwebsol.com/Back_end/${JSON.parse(customer.deliver_pic)[0]}`}
                    alt="Delivery"
                    style={{ width: '58px', cursor: 'pointer' }}
                    onClick={() => handleImageClick(JSON.parse(customer.deliver_pic).map(pic => `https://pms.usmanmobiles.logicalwebsol.com/Back_end/${pic}`))}
                  />
                ) : 'No picture'}
              </td>
              <td>
                <button className="btn btn-danger" onClick={() => handleDelete(customer.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
        <ImageGalleryModal isOpen={isModalOpen} images={activeImages} onClose={handleCloseModel} />
      </table>
    </div>
  );
};

export default CompletedJobs;

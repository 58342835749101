import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, InputAdornment } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import './Home.css';
import { useAuth } from './AuthContext';

const Home = () => {
const [username, setUsername] = useState('');
const [password, setPassword] = useState('');
const [showPassword, setShowPassword] = useState(false);
const [loginError, setLoginError] = useState('');
const [loginSuccess, setLoginSuccess] = useState(false);
const { logIn } = useAuth();
const navigate = useNavigate();

const handleSubmit = async (event) => {
event.preventDefault();
try {
var formdata = new FormData();
formdata.append("username", username);
formdata.append("password", password);
formdata.append("endpoint", "login");
var requestOptions = {
method: 'POST',
body: formdata,
redirect: 'follow'
};
console.log(loginSuccess,"login success var")
const response = await fetch("https://pms.usmanmobiles.logicalwebsol.com/Back_end/login", requestOptions);
const result = await response.json();

if (result.status === "success" && result.token && result.token !== 'undefined' && result.token !== 'empty') {

  logIn(result.token);
  setLoginError('');
  setLoginSuccess(true);
} else {
    console.log("here the fail status",result.status);
    setLoginError(result.status || 'Login failed. Please try again.');
    console.log(loginSuccess,"login success var in request fail")
}

} catch (error) {
console.log('error', error);
setLoginError('Login failed. Please try again.');
}
};
useEffect(() => {
  if (loginSuccess) {
    // Automatically close the form upon successful login
    navigate('/completed-jobs'); // Navigate to completed jobs page after login
  }
}, [loginSuccess,  navigate]);


return (
<div className="main-login">
<div className="login-form-container">
            <h2 style={{ textAlign: 'center' }}>Login</h2>
            <div>
                <form className="login-form" onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="username" className="form-label">Username</label>
                        <input type="text" className="form-control" id="username" value={username} onChange={(e)=>
                        setUsername(e.target.value)}
                        required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Password</label>
                        <div className="password-input-container">
                            <input type={showPassword ? 'text' : 'password' } className="form-control" id="password"
                                value={password} onChange={(e)=> setPassword(e.target.value)}
                            required
                            />
                            <InputAdornment position="end">
                                <IconButton onClick={()=> setShowPassword((prev) => !prev)} edge="end">
                                    {showPassword ?
                                    <VisibilityOffIcon /> :
                                    <VisibilityIcon />}
                                </IconButton>
                            </InputAdornment>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary login">Log In</button>
                    {loginError && <p className="error-message">{loginError}</p>}
                    {loginSuccess && <p className="success-message">Login Successful</p>}
                </form>
            </div>
            <p style={{ textAlign: 'center' }}>
                <button className="btn btn-link" onClick={()=> navigate('/forgot-password')} >
                    Forgot Password?
                </button>
            </p>
        </div>
</div>
);
};

export default Home;
import React, { useState, useEffect } from 'react';

const ImageGalleryModal = ({ isOpen, images, onClose }) => {
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    if (!isOpen) {
      setPreviewImage(null); // Reset the preview image when the modal is closed
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleClosePreview = (e) => {
    e.stopPropagation();
    setPreviewImage(null);
  };

  const handleCloseModal = () => {
    setPreviewImage(null);
    onClose(); // Call the onClose function to close the modal
  };

  return (
    <div className="modal show" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }} onClick={handleCloseModal}>
      <div className="modal-dialog modal-lg" onClick={e => e.stopPropagation()}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Image Gallery</h5>
            <button type="button" className="close" onClick={handleCloseModal}>
              &times;
            </button>
          </div>
          <div className="modal-body">
            {previewImage ? (
              <div style={{ textAlign: 'center' }}>
                <img src={previewImage} className="img-fluid" alt="Enlarged preview" onClick={handleClosePreview} />
                <button
                  type="button"
                  className="close"
                  onClick={handleClosePreview}
                  style={{ position: 'absolute', right: 20, top: 20 }}
                >
                  &times;
                </button>
              </div>
            ) : (
              <div className="row">
                {images.map((image, index) => (
                  <div className="col-md-4" key={index} onClick={() => setPreviewImage(image)}>
                    <img src={image} className="img-fluid" alt={`Gallery ${index}`} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageGalleryModal;

import React from "react";
import "./Footer.css"





const Footer = () => {
  return (
    
    <div>

<footer>
<div className='footer text-center p-3' style={{ backgroundColor: '#333336' }}>
       <h6 className="footer_head"> Design And Developed By:</h6>
        <a className='text' href='https://logicalwebsol.com/'>
          logicalwebsol.com
        </a>
      </div>
</footer>

    </div>

  );
};

export default Footer;

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import "../Components/Customer.css";
import { useNavigate } from 'react-router-dom';
import { useCustomers } from './CustomerContext';
const MatrimonialTable = () => { 
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const intervalRef = useRef(null);

  const [formData, setFormData] = useState({
    name: '',
    cnic: '',
    address: '',
    mob_no: '',
    mob_brand: '',
    charges: '',
    user_pic: [],
  });
  const { refetch: refreshCustomers } = useCustomers();
  const [capturedImages, setCapturedImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [captureCount, setCaptureCount] = useState(0);
  const [showCamera, setShowCamera] = useState(true);
  const [showVideo, setShowVideo] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (captureCount >= 9) {
      clearInterval(intervalRef.current);
      setShowCamera(false);
    
      setShowVideo(false);
      if (streamRef.current) {
        const tracks = streamRef.current.getTracks();
        tracks.forEach(track => track.stop());
      }
    }
  }, [captureCount]);

  const handleStartCamera = async () => {
    clearInterval(intervalRef.current); // Clear any existing interval
    setCaptureCount(0); // Reset capture count
    setCapturedImages([]); // Clear previous captured images
    setSelectedImages([]); // Clear selected images
    setShowVideo(true);
    try {
      const constraints = {
        video: { facingMode: "environment" } // 'user' for front camera, 'environment' for rear camera
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      videoRef.current.srcObject = stream;
      streamRef.current = stream;
      setShowCamera(true);
     
      intervalRef.current = setInterval(handleCapturePicture, 2000);
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };

  const tryNew = () => {
    setShowVideo(true);
    handleStartCamera();
  }

  const handleCapturePicture = () => {
    const video = videoRef.current;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Resize the image by drawing it on a smaller canvas
    const targetWidth = 320;  // Target width, adjust as needed
    const targetHeight = video.videoHeight / (video.videoWidth / targetWidth);
    canvas.width = targetWidth;
    canvas.height = targetHeight;

    ctx.drawImage(video, 0, 0, targetWidth, targetHeight);

    // Adjust the quality of the image
    const quality = 0.5;  // Adjust quality from 0 to 1 as needed

    const dataURL = canvas.toDataURL('image/jpeg', quality);
    setCapturedImages(prev => [...prev, dataURL]);
    setCaptureCount(prev => prev + 1);
  };

  const handleImageSelect = (image) => {
    if (selectedImages.includes(image)) {
      setSelectedImages(selectedImages.filter(item => item !== image));
    } else {
      setSelectedImages([...selectedImages, image]);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedImages.length === 0) {
      alert("Please select at least one image.");
      return;
    }
    const formDataToSend = new FormData();
    Object.keys(formData).forEach(key => {
      if (key === 'user_pic') {
        // Convert each selected image dataURL to a Blob, then to a File
        selectedImages.forEach((imgDataUrl, index) => {
          fetch(imgDataUrl)
            .then(res => res.blob())
            .then(blob => {
              const imgFile = new File([blob], `user_pic_${index}_${new Date().toISOString()}.jpeg`, { type: 'image/jpeg' });
              formDataToSend.append('user_pic[]', imgFile);
            });
        });
      } else {
        formDataToSend.append(key, formData[key]);
      }
    });
  
    // Assuming you're sending the form data after the last image file is prepared
    setTimeout(async () => {
      for (let pair of formDataToSend.entries()) {
        console.log(`${pair[0]}:`, pair[1]);
      }
      const token = localStorage.getItem('token');
      try {
        const response = await axios.post('https://pms.usmanmobiles.logicalwebsol.com/Back_end/customers', formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
          }
        });
        console.log('From db resp',response)
        if (response.data.error) {
          alert(response.data.error);
          return;
        }
    
        setSuccessMessage('Data submitted successfully!');
        refreshCustomers();
        navigate('/pending-jobs');
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    }, 1000); // This delay is arbitrary; ensure all images are processed before sending
  };

  return (
    <div className="container mt-5 Customer_contain">
      <h1 className='form-head'>Add Customer</h1>
      <form onSubmit={handleSubmit}>
        <div className="main-container">
          <div className="form-container">
            <div className="left-side">
              <div id='login'>
              <div className="row">
                <div className="col">
                  <label htmlFor="name" className="form-label">Name</label>
                  <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleInputChange} required />
                </div>
                <div className="col">
                  <label htmlFor="cnic" className="form-label">CNIC</label>
                  <input type="text" className="form-control" id="cnic" name="cnic"  placeholder="Enter CNIC without dashes" value={formData.cnic} onChange={handleInputChange}  />
                </div>
              </div>
            
              <div className="mb-3">
                <label htmlFor="address" className="form-label">Address</label>
                <input type="text" className="form-control" id="address" name="address" value={formData.address} onChange={handleInputChange} required />
              </div>
              <div className="row">
                <div className="col">
                  <label htmlFor="mob_no" className="form-label">Mobile Number</label>
                  <input type="text" className="form-control" id="mob_no" name="mob_no" value={formData.mob_no} onChange={handleInputChange} required />
                </div>
                <div className="col">
                  <label htmlFor="mob_brand" className="form-label">Mobile Brand</label>
                  <input type="text" className="form-control" id="mob_brand" name="mob_brand" value={formData.mob_brand} onChange={handleInputChange} required />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label htmlFor="charges" className="form-label">Charges</label>
                  <input type="text" className="form-control" id="charges" name="charges" value={formData.charges} onChange={handleInputChange} required />
                </div>
              </div>
              </div>
              <div className='video_display'>
                {showVideo && (
                  <video ref={videoRef} autoPlay className="video-element"></video>
                )}
                {showCamera ? (
                  <div className='start-button'>
                    <button type="button" className="btn btn-secondary" onClick={handleStartCamera}>Start Camera</button>
                  </div>
                ) : (
                  <div className='start-button'>
                    <button type="button" className="btn btn-secondary" onClick={tryNew}>Recapture</button>
                  </div>
                )}
              </div>
            </div>
            <div className="right-side">
      <div className="mb-3 web-container">
        {capturedImages.map((image, index) => (
          <div key={index} className='image-div'>
            <div className="image-wrapper">
              <img src={image} alt={`Captured ${index}`} onClick={() => handleImageSelect(image)} className={`captured-image ${selectedImages.includes(image) ? 'selected' : ''}`} />
              {selectedImages.includes(image) && (
                <div className="tick-button">✔</div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
          </div>
        </div>
        <div className="sub-button">
          <button type="submit" className="btn btn-primary submit-button">Submit</button>
        </div>
      </form>
      {successMessage && (
        <div className="alert alert-success" role="alert">
          {successMessage}
        </div>
      )}
    </div>
  );
};

export default MatrimonialTable;
